.radio {
  display: flex;

  &--horizontal {
    flex-direction: row;
  }

  &--vertical {
    flex-direction: column;

    label {
      margin-bottom: .5rem;
    }
  }

  h4 {
    margin-bottom: .5rem;
    display: flex !important;
    .loader-wrapper {
      position: static;
      margin: 0 0 0 12px;
      height: 15px;
      width: 15px;

      svg {
        fill: $colour-accent-alt;
        height: 100%;
        width: 100%;
      }
    }
  }

  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: 1rem;

    input {
      margin-right: .5rem;
    }
  }
}
