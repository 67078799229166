.table-cell-actions-overlayPlaylistsTracksAdd,
.table-cell-actions-overlayPlaylistsTracksAddMulti,
.table-cell-actions-overlayPlaylistsTracksAddSearch {
  .icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    left: auto;
    margin: 0;
    position: relative;
    top: auto;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.table-cell-actions-overlayPlaylistsTracksAddMulti {
  .icon-success {
    cursor: default;
  }
}
