.table-typeahead{
  position: relative;
  background-color: $colour-background-mid;
  border-top-right-radius:5px;
  border-top-left-radius:5px;
  border-bottom:1px solid $colour-background-light;
  padding:0;
  .metadata{
    padding:0 0 9px 0;
  }
}