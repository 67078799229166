.form{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $colour-background-mid;
  width:100%;
  padding:10px 20px;
  margin:0;
  .form-submit{
    background-color: transparent;
    border:1px solid $colour-background;
    outline: none;
    border-radius: 4px;
    padding:7px 0;
    color:$colour-text;
    transition:0.3s;
    margin-top:10px;
    min-height: 40px;
    &:hover{
      background-color: $colour-background-light;
      border:1px solid $colour-text-link;
    }
  }
  .form-message{
    text-align: center;
    color: $colour-text;
    font-size:12px;
  }
  &.scroll{
    overflow: scroll;
    margin-bottom: 75px;
    padding-bottom: 30px;
  }
  &.isOverlay{
    margin-bottom:0;
  }
}
