.container-playlistTracks-table-wrapper, .container-playlistTracksSplit-table-wrapper{
  .table-cell-play{
    .icon-wrapper{
      .icon-alert{
        color: $colour-error;
      }
    }
  }
  .selected-indicator{
    font-size: 12px;
  }
  .table-cell-library-action{
    text-decoration: underline;
    cursor: pointer;
  }
}