.venuePlaylists-table-row-actions{
  a{
    margin:0 !important;
  }
  .icon-wrapper{
    .icon{
      &.icon-checkmark-circle{
        color: $colour-success;
      }
      &.icon-close-circle{
        color: $colour-error;
      }
    }
  }
}