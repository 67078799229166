.container-nav{
  min-height:60px;
  width:100%;
  display: flex;
  .container-nav-left{
    display: flex;
    width:70%;
    svg{
      max-height: 30px;
      max-width: 30px;
      min-width: 30px;
      margin:20px 20px 0 27px;
    }
    a{
      padding:24px 20px 20px 20px;
      margin:0 2px;
      text-decoration: none;
      color:$colour-text-weak;
      font-size: 14px;
      &:hover{
        box-shadow: inset 0px -2px 0px 0px $colour-text-weak;
        color: $colour-accent-alt;
      }
      &.is-active{
        box-shadow: inset 0px -2px 0px 0px $colour-accent;
        color: $colour-text;
      }
    }
  }
  .container-nav-right{
    width:30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right:20px;
    .icon{
      height:30px;
      width:30px;
      margin:15px 6px 0;
    }
  }
}
.container-nav-mobile{
  position: relative;
  width:100%;
  height: 100%;
  .nav-mobile-logo-wrapper{
    position: relative;
    height: 100%;
    display: flex;
    width:100%;
    justify-content: space-between;
    align-items: center;
    svg{
      max-height: 30px;
      max-width: 30px;
      min-width: 30px;
      margin:20px 20px 0 27px;
    }
    div{
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      margin-right: 27px;
    }
  }
  .nav-mobile-header{
    height:45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon{
      width:33px;
      height: 33px;
    }
  }
  .nav-mobile-menu-wrapper{
    position: fixed;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    background-color: $colour-background;
    transform: translateX(-100%);
    padding:0 12px;
    &.open{
      transform: translateX(0%);
    }
    a{
      padding:24px 20px 20px 20px;
      margin:0 2px;
      text-decoration: none;
      color:$colour-text-weak;
      font-size: 14px;
      &:hover{
        box-shadow: inset 0px -2px 0px 0px $colour-text-weak;
        color: $colour-accent-alt;
      }
      &.is-active{
        box-shadow: inset 0px -2px 0px 0px $colour-accent;
        color: $colour-text;
      }
    }
  }
}