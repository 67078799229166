.form-typeahead-wrapper {
  .form-typeahead {
    background-color: $colour-background-light;
    margin: 15px 0;

    &.has-error {
      border: 1px solid $colour-error;
      box-shadow: 0 0 5px rgba($colour-error, 1);
    }

    .typeahead-wrapper {
      margin: 5px 0;
    }

    .typeahead-input {
      background: none;
    }
  }

  .form-typeahead__error-message {
    color: $colour-error;
    font-size: 13px;
  }
}
