.container-playlist{
  flex-direction: column;
  .container-tabs{
    &.library{
      margin-bottom:0;
    }    
  }
  .container-isPlaylist{
    .container-tabs{
      margin:0;
      .tabs-wrapper{
        margin:0 0 3px 0;
      }
    }
  }
}
