.form-checklist-wrapper {
  .form-checklist__error-message {
    color: $colour-error;
    font-size: 13px;
    margin-top: 0;
  }

  .form-checklist__controls {
    .button {
      margin-right: 1rem;
    }
  }

  h3 {
    margin: 1rem 0 .5rem 0;
    //border-bottom: 1px solid white;
    padding-bottom: 8px;
  }

  .form-checklist {
    border-bottom: 1px solid $colour-text-weak;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      flex: 0 50%;
      margin-bottom: 1rem;

      label {
        cursor: pointer;
      }

      input {
        margin-right: 1rem;
      }
    }

    &.has-error {
      border: 1px solid $colour-error;
      box-shadow: 0 0 5px rgba($colour-error, 1);
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .form-checklist__error-message {
    color: $colour-error;
    font-size: 13px;
  }
}
