@import './sass/_all';
html{
  position: relative;
  height:100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: $fontFamily;
  height:100%;
  position: relative;
}
#root{
  position: relative;
  height: 100%;
}