.container{
  position: relative;
  display: flex;
  &.container-height-full, &.controls{
    height:100%;
  }
  &.container-tabview{
    margin-left:20px;
    margin-right:20px;
    height:100%;
  }
  &.container-table{
    display: block;
    margin:0 auto;
    background-color: $colour-background-mid;
    height: 100%;
    width:100%;
    overflow: scroll;
    &.controls{
      margin-bottom: 120px;
    }
    &.no-controls{
      margin-bottom: 75px;
      height:auto;
    }
    &.loading{
      &.empty{
        margin-bottom: 75px;
      }
    }
  }
  &.column{
    flex-direction: column;
    overflow: hidden;
  }
  &.overflowVisible{
    overflow: visible;
  }
  &.scroll{
    overflow: scroll;
    margin-bottom: 75px;
  }
  &.maxWidth{
    width:100%;
  }
  &.maxHeight{
    max-height: calc(100vh - 190px);
    overflow-y: auto;
  }
  .container-overlay{
    .container-table{
      margin-bottom:50px;
    }
  }
}
