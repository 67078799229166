.interleave-creator {
  .interleave-creator__ratio {
    margin: 1rem 0 0 0;

  }
}

.interleave-creator__ratio-select {
  color: $colour-background;
  font-size: 14px;
  margin: 0 10px;
  width: auto;
}
