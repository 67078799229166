.container-playlistsImporter{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding:0 20px 130px 20px;;
  p{
    color:white;
  }
  .importer-login{
    width:100%;
    text-align: center;
  }
  .importer-playlists{
    width:320px;
    .playlist{
      cursor: pointer;
      padding:10px 20px;
      margin:5px 0;
      border:1px solid $colour-background-light;
      border-radius: 5px;
      &:hover{
        border:1px solid $colour-text-link;
      }
      &.active{
        border:1px solid $colour-accent-alt;
      }
    }
  }
  .importer-tracks{
    width:100%;
  }
}