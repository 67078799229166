.reports-data {
  font-size: 24px;
  margin: auto;
  padding: 1rem;

  @media only screen and (min-width: 1400px) {
    font-size: 30px;
  }

  h6 {
    margin: 0 1rem 0 0;
  }
}

.business-reports__pdf-mode {
    .reports-playlist {
        .reports-data h6 {
            margin-top: 1rem;
        }
    }
}
