.settings-container{
  .parent-typeahead-wrapper, .owner-typeahead-wrapper{
    position: relative;
    margin: 15px 0;
    padding:0;
    .typeahead-label{
      display: block;
      color: #f4f4f4;
      transition: 0.3s;
      outline: none;
      pointer-events: none;
      letter-spacing: 0.5px;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .typeahead-wrapper{
      padding:0;
      .typeahead-input{
        height: 39px;
        padding: 7px 10px 7px 33px;
      }
      .icon-ios-search{
        top:9px;
        left:6px;
      }
      .icon-ios-close{
        right:6px;
        top:-34px;
      }
    }
  }
  .radio{
    h4{
      display: block;
      color: #f4f4f4;
      transition: 0.3s;
      outline: none;
      pointer-events: none;
      letter-spacing: 0.5px;
      font-size: 14px;
      margin: 10px 0;
      font-weight: 100;
    }
  }
  .parent-overlay{
    position: absolute;
    width:450px;
    top:calc(50%);
    left:calc(50% - 150px);
    background-color: $colour-background-mid;
  }
}