.form-texarea-wrapper{
  position: relative;
  margin: 15px 0;

  .texarea {
    display: block;
    border:none;
    outline: none;
    min-height: 150px;
    background-color: $colour-background-light;
    width:100%;
    padding:7px 10px;
    color: $colour-text; 
    border-bottom: 1px solid $colour-background-mid;

    &.has-error {
      border:1px solid $colour-error;
      box-shadow: 0 0 5px rgba($colour-error, 1);
    }
  }

  .texarea__error-message {
    color: $colour-error;
    font-size: 13px;
    margin-bottom: 0;
  }
}
