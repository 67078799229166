.table-controls{
  background-color:$colour-background-mid;
  border-top:1px solid $colour-background;
  position: fixed;
  bottom:80px;
  left:20px;
  width:calc(100% - 40px);
  height:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0 20px;
  .table-controls-left{
    width:70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    select{
      margin-left:5px;
    }
  }
  .table-controls-right{
    width:30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    .icon, .loader-wrappper{
      width:20px;
      height:20px;
      margin:0 5px;
    }
    .loader-wrapper{
      position: relative;
      margin: -2px 0 0px 0;
      top: 0;
      left: 0;
      svg{
        width:20px;
        height:20px;
      }
    }
    span{
      margin:0 3px;
      min-width: 36px;
    }
  }
}
