.overlay-playlist-add-to-venues__updating {
  margin: 0 0 .5rem .5rem;

  .loader-wrapper {
    display: inline-block;
    height: 20px;
    margin: 0 .5rem 0 0;
    position: relative;
    left: 0;
    top: -2px;
    width: 20px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}
