.container-playlistsSearch-wrapper{
  .playlists-search-typeahead-wrapper{
    width:100%;
    .typeahead-wrapper-playlistsSearch{
      padding:0;
      margin:0;
      .icon-ios-search{
        left:10px;
        top:17px;
      }
      .icon-ios-close{
        top: -40px;
      }
      .typeahead-input{
        padding:17px 5px 17px 35px;
      }
      .typeahead-results-playlistsSearch{
        top:54px;
      }
    }
  }
}