.container-upload-wrapper {
  .upload-input {
    margin: 1rem 0;
  }

  .upload-controls {
    .button {
      margin: 1rem 1rem 1rem 0;
    }

    .button__processing {
      pointer-events: none;

      .loader-wrapper {
        display: inline-block;
        height: 20px;
        margin: 0;
        position: relative;
        left: -10px;
        top: -2px;
        width: 20px;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .upload-input--upload-complete,
  .upload-controls--upload-complete {
    pointer-events: none;
  }

  .icon-alert {
    color: $colour-error !important;
  }

  .upload__completion-overlay {
    background: rgba(0 ,0 ,0 , .75);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 200;
  }

  .upload__completion-message {
    background: $colour-background;
    border-radius: 4px;
    font-size: 1.25rem;
    left: 50%;
    max-height: 80vh;
    overflow: auto;
    padding: 4rem;
    position: absolute;
    text-align: center;
    top: 35%;
    transform: translate(-50%, -35%);

    .icon-wrapper {
      color: $colour-success;
      font-size: 3rem;
      margin: 0 0 1rem 0;

      .icon {
        color: $colour-success;
      }
    }

    p {
      margin: 0 0 1rem 0;
    }

    ul {
      text-align: left;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        font-size: 1rem;
        margin-bottom: .75rem;
      }
    }

    button {
      font-size: 1rem;
    }
  }

  .table-cell-upload {
    .loader-wrapper {
      height: 23px;
      position: relative;
      top: 0;
      left: 0;
      width: 23px;
      margin: 0 auto;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
