.table-actions-playlistTracks, .table-actions-playlistTracksSplit{
  flex-wrap:wrap;
  min-height: 85px !important;
  .table-actions-right{
    opacity: 0;
    .icon{
      cursor: pointer;
      transform: scale(0);
      transition: 200ms all;
      margin:0 10px !important;
    }
    &.active{
      opacity: 1;
      .icon{
        transform: scale(1);
      }
    }
  }
  .metadata{
    width:100%;
    padding: 8px 0;
  }
}
