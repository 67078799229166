.table-actions-library{
  flex-wrap:wrap;
  //min-height: 75px !important;
  padding:0 10px !important;
  .table-actions-left{
    .icon-funnel, .icon-checkmark-circle{
      cursor: pointer;
      width:25px;
      height:25px;
      margin:0 5px;
    }
    .icon-checkmark-circle{
      &.icon-active{
        color:$colour-success;
      }
    }
  }
  .table-actions-right{
    display: none;
    opacity: 0;
    .icon{
      cursor: pointer;
      transform: scale(0);
      transition: 200ms all;
      margin:0 10px !important;
    }
    &.active{
      opacity: 1;
      .icon{
        transform: scale(1);
      }
    }
  }
  .metadata{
    width:100%;
    padding: 8px 0;
  }
}
