.container-venueHardware {
  .devices__add-new {
    margin-bottom: 1rem;
  }

  .devices {
    padding-right: 20px;
    width: 100%;
  }

  .venue-hardware__old-device-toggle {
    margin-bottom: 2rem;
  }
}
