.container-messages-wrapper{
  .badge{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 12px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $colour-text-link;
  }
  .icon-sync, .icon-alert{
    position: absolute;
    top: -8px;
    right: -4px;
    width: 22px !important;
  }
  .icon-sync{
    color: $colour-accent-alt;
    animation: spin 4s linear infinite;
    @keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }
  }
  .icon-alert{
    pointer-events: none;
    color: $colour-error !important;
  }
  .container-messages{
    display: none;
    position: absolute;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
    top: 60px;
    right: 20px;
    width:460px;
    height:500px;
    background-color: $colour-background-light;
    z-index: 9999;
    &.active{
      display: initial;
    }
    .message-item{
      display: flex;
      align-items: center;
      padding:5px;
      border-bottom: 1px solid $colour-background-mid;
      margin:0 5px;
      .icon{
        margin:0;
        padding:10px;
        min-width: 30px;
      }
      .icon-checkmark{
        color:$colour-success;
      }
      .icon-ios-warning{
        color:$colour-error;
      }
    }
  }
}
