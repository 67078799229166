.form-input-wrapper{
  position: relative;
  margin: 15px 0;
  .input{
    display: block;
    outline: none;
    background-color: $colour-background-light;
    width:100%;
    padding:7px 10px;
    color: $colour-text;
    border: 1px solid $colour-background-mid;

    &[type='checkbox'] {
      width: 36px;
      height: 36px;
    }

    &.has-error {
      border:1px solid $colour-error;
      box-shadow: 0 0 5px rgba($colour-error, 1);
    }

    &:focus{
      border:1px solid $colour-text-link;
      box-shadow: 0 0 5px rgba($colour-text-link, 1);
    }

    &:disabled {
      background-color: #021b2b;
      cursor: not-allowed;
    }
  }
  .focus-border{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: $colour-background-light;
    transition: 0.3s;
  }
  .input-label{
    display: block;
    color: $colour-text;
    transition: 0.3s;
    outline: none;
    pointer-events: none;
    letter-spacing: 0.5px;
    font-size: 12px;
  }
  .input:-webkit-autofill{
    box-shadow: 0 0 0 1000px $colour-background inset;
    -webkit-text-fill-color: $colour-text;
  }
  .input::-webkit-input-placeholder {
    color: $colour-text;
  }

  .form-input__error-message {
    color: $colour-error;
    font-size: 13px;
    margin-bottom: 0;
  }

  .edit-action-wrapper{
    position: absolute;
    right:12px;
    top:36px;
    .icon{
      width:24px;
      height: 24px;
    }
  }
}
