.form-label{
  display: block;
  color: $colour-text; 
  transition: 0.3s; 
  outline: none;
  pointer-events: none;
  letter-spacing: 0.5px;
  font-size: 14px;
  margin-bottom: 10px;
  span{
    color:$colour-error;
  }
}
