.dashboard {
  margin-top: 40px;

  .reports-data {
    max-height: 350px;
    overflow: auto;

    ul {
      list-style-position: inside;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    p, li {
      font-size: 1rem;
      margin: 0 0 1rem 0;

      a {
        color: $colour-text
      }

      .icon-wrapper {
        cursor: pointer;
        display: inline-block;
        padding-right: 10px;
        position: relative;
        top: 3px;
      }

      &.reports-data__list-item--has-icon {
        display: flex;
      }
    }

    .reports-data__secondary {
      display: block;
      font-size: .8rem;
      margin-top: .25rem;
    }
  }
}
