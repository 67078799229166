.reports-unit {
  width: 100%;
}

.reports-unit__col-12 {
  width: 12.5%
}

.reports-unit__col-20 {
  width: 20%;
}

.reports-unit__col-25 {
  width: 25%;
}

.reports-unit__col-33 {
  width: 33.3%;
}

.reports-unit__col-50 {
  width: 50%;
}

.reports-unit__col-66 {
  width: 66.7%;
}

.reports-unit__col-75 {
  width: 75%;
}

.reports-unit__col-100 {
  width: 100%;
}

.reports-unit__inner {
  background: $colour-background-mid;
  margin: .75rem;

  .recharts-wrapper {
    margin: auto;
  }
}

.business-reports__pdf-mode {
  .reports-unit__inner {
    background: transparent;
    color: $colour-background-mid;
  }
}

.reports-unit__header {
  border-bottom: 1px solid $colour-background;
  font-size: 16px;
  margin: 0;
  padding: .5rem 1rem;
  text-align: left;

  span {
    margin-right: .75rem;
  }
}
