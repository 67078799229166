.table-row{
  height:30px;
  user-select: none;
  &.user-select{
    user-select: text;
  }
  &:hover{
    .table-cell{
      background-color: $colour-background-light;
    }
  }
  &.isPlaying{
    .table-cell{
      background-color: $colour-text-link;
    }
    .table-cell-play{
      .icon{
        color:#ffffff;
      }
    }
    .table-cell-actions{
      div{
        .icon{
          color:#ffffff;
        }
      }
    }
    .table-cell-energy{
      .energy{
        margin:0 auto;
        width:33px;
        height: 21px;
        color: #ffffff;
        .icon-wrapper{
          .icon{
            color: #ffffff;
          }
        }
      }
    }
  }
  &.checked{
    .table-cell{
      background-color:darken($colour-accent,20);
      border-bottom: 1px solid darken($colour-accent,30);
    }
  }
  .table-cell-actions{
    text-align: center;
    &.table-cell-actions-2{
      width:80px;
    }
    &.table-cell-actions-3{
      width:110px;
    }
    &.table-cell-actions-playlistOrder .icon-wrapper {
      cursor: grab;
    }
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        display: block;
        height: 23px;
        margin: 2px 3px 0 0;
      }
      .icon{
        width:23px;
        height:23px;
        margin:0 5px;
      }
      select{
        margin:0 5px;
      }
    }
    .icon-wrapper {
      cursor: pointer;
    }

    .loader-wrapper {
      display: flex;
      height: 23px;
      position: relative;
      top: 0;
      left: 0;
      width: 36px;
      margin: 0;
      padding: 0 8px;
    }
  }
}
