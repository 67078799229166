.container-venue-controls-wrapper{
  width:100%;
  margin:0 1%;
  h2{
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 4px;
    border-bottom:1px solid $colour-background-light;
    margin:5px;
    padding:10px;
    text-align: center;
    width:100%;
  }
  &.venue-controls{
    h2 {
      margin: 5px 0;
    }
  }
  &.venue-playlists,
  &.venue-energy-slider{
    width: 50%;
  }

}
