.container-businessVenues {
  h3 {
    margin-left: 10px;
  }
}

.container-filter-wrapper{
  align-items: center;
  margin-bottom:10px;
  .filter{
    margin-right:9px;
  }
  .icon{
    width:40px;
    height:40px;
    margin: 0 9px;
  }
}

.container-businessVenuescards{
  flex-wrap: wrap;
  align-content: flex-start;
}
