.typeahead-wrapper{
  position: relative;
  font-size: 13px;
  width:100%;
  padding:0 20px;
  margin:7px 0;
  .icon-ios-search {
    position: absolute;
    width:20px;
    height:20px;
    left: -10px;
    top: 7px;
  }
  .loader-wrapper {
    margin: 0;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 12px;
    top: 7px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
  .icon-ios-close{
    cursor: pointer;
    display: none;
    position: absolute;
    width:30px;
    height:30px;
    right: 0;
    top: -30px;
    &.active{
      display: inline;
    }
  }
  .typeahead-input{
    width:100%;
    outline: 0;
    border:0;
    padding:7px 5px 7px 25px;
    width:100%;
    color: $colour-text;
    background-color: $colour-background-mid;
    &::-webkit-input-placeholder {
      color: $colour-text;
    }
  }
  .typeahead-results{
    box-shadow: 3px 3px 8px $colour-background;
    border-top: none;
    position: absolute;
    top: 40px;
    left:0;
    z-index: 999;
    background-color:$colour-background-light;
    width:100%;
    max-height: 66vh;
    overflow-y: scroll;
    height: 0;
    &.typeahead-results-active{
      height:auto;
      //border: 1px solid $colour-background;
    }
    .typeahead-result{
      display: block;
      margin:0;
      padding:10px 10px;
      text-decoration: none;
      color:$colour-text;
      &:not(.typeahead-result--no-result):hover{
        cursor: pointer;
        background-color:$colour-background;
      }
      &.typeahead-active{
        background-color:$colour-background;
      }
    }
    h2{
      font-size: 16px;
      margin: 0 0 0 10px;
      padding:5px 0;
      border-bottom: 1px solid $colour-text-link;
      text-transform: uppercase;
    }
  }
}
