.container-tabs{
  width:100%;
  margin:10px 0;
  flex-direction: row;
  .tabs-wrapper{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width:100%;
    min-height:30px;
    height:30px;
    margin:0 20px;
    padding-bottom:2px;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar-thumb {
      background:$colour-background-mid;
      border-radius: 1px;
    }
    .tabs-left{
      display: flex;
      justify-content: left;
      flex-direction: row;
      .tab{
        text-decoration: none;
        color:$colour-text-weak;
        padding:0 10px;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        &.active{
          color: $colour-text;
          box-shadow: 0px 2px 0px 0px $colour-accent-alt;
        }
        &:hover:not(.active){
          color: $colour-accent-alt;
          box-shadow: 0px 2px 0px 0px $colour-background-light;
        }
        .icon{
          margin-left:9px;
        }
        .tab-badge{
          margin-left:3px;
          font-size: 12px;
          padding: 0 4px;
          border-radius: 4px;
          white-space:nowrap;
          background-color: $colour-text-link;
          color: $colour-text;
        }
      }
    }
    .tabs-right{
      display: flex;
      justify-content: right;
      flex-direction: row;
      align-items: center;
      .tabs-header{
        width:100%;
        min-width: 99px;
        text-align: right;
        font-size: 14px;
        color: $colour-accent-alt;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        a {
          color: $colour-accent-alt;
        }

        .loader-wrapper {
          position: static;
          margin: 0;
          height: 20px;
          width: 20px;

          svg {
            fill: $colour-accent-alt;
            height: 100%;
            width: 100%;
          }
        }
      }
      .tabs-actions{
        display: flex;
        margin-left:20px;
      }
    }
  }

}
