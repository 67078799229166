.playlist-item{
  padding:20px 0;
  border-top:1px solid $colour-background-mid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover{
    background-color: $colour-background-light;
    cursor: pointer;
  }
  .playlist-item-play{
    width:10%;
    .icon{
      width: 40px;
      height: 40px;
    }
  }
  .playlist-item-info{
    width:80%;
    .playlist-item-name{
      font-size: 23px;
    }
    .playlist-item-tracks{
      margin-left: 12px;
      color: $colour-accent-alt;
    }
    .playlist-item-style{
      font-size: 14px;
    }
    .playlist-item-genres{
      font-size: 13px;
      text-transform: lowercase;
      color:$colour-text-link;
      .playlist-item-genre, .playlist-item-tag{
        margin:0 5px;
        &:first-child{
          margin-left:0;
        }
      }
    }
  }
  .playlist-item-actions{
    width:10%;
    .icon{
      width: 40px;
      height: 40px;
    }
  }
}