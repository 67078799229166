.card-container{
  background-color: $colour-background-mid;
  height:200px;
  width:31%;
  margin:0 1% 20px 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color:$colour-text;
  border: 1px solid $colour-background-light;
  border-radius:4px;
  &.mobile{
    width:99%;
  }
  &:hover{
    border:1px solid $colour-accent-alt;
  }
  .icon{
    z-index: 999;
    width:30px;
    height:30px;
    color: $colour-accent-alt;
  }
}