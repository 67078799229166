.badge{
  margin: 0 5px;
  background-color: darken($colour-accent,10);
  font-size: 12px;
  padding: 0 4px;
  border-radius: 4px;
  white-space:nowrap;
  &.badge-action{
    background-color: $colour-text-link;
    cursor: pointer;
  }
  &.badge-link{
    background-color: $colour-text-link;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    text-decoration: none;
    width: 20px;
  }
  &.badge-error{
    background-color: $colour-error;
  }
}
