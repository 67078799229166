.container-overlay-saved-calendar-add-to-calendar {
  overflow: visible !important;

  @at-root .container-overlay {
    overflow: visible !important;
  }


  .overlay-saved-calendar-add-to-calendar__date-section {
    align-items: center;
    display: flex;
    margin: 1rem 0;
    overflow: visible;
    position: relative;

    .overlay-saved-calendar-add-to-calendar__date-select {
      width: 50%;

      &:first-child {
        margin-right: 1rem;

        .react-calendar {
          left: 0;
        }
      }

      &:last-child {
        margin-left: 1rem;

        .react-calendar {
          right: 0;
        }
      }

      input {
        background-color: $colour-background;
        border:none;
        color: $colour-text;
        cursor: pointer;
        display: block;
        outline: none;
        padding:7px;
        user-select: none;
        width:100%;
      }

      .react-calendar {
        top: 0;
      }
    }
  }

}
