.reports-playlist {
  display: flex;

  .dashboard__data {
    font-size: 24px;
  }
}

.business-reports__pdf-mode {
 .reports-playlist {
   border-bottom: 1px solid $colour-background-mid;

   &:last-child {
     border-bottom: none;
   }
 }
}

.reports-playlist__data {
  width: 25%;
  text-align: left;
}

.reports-playlist__form {
  padding: 1rem 1rem 0 1rem;
  text-align: left;
}

.reports-playlist__genres {
  width: 25%;

  h6 {
    margin: 2rem 0 0.5rem 0;
    font-size: 1rem;
  }

  .recharts-responsive-container {
    margin-top: -40px;
  }
}

.dashboard__playlist-genres-pie-chart {
  margin-top: -30px;
}

.reports-playlist__breakdown {
  width: 50%;

  h6 {
    margin: 2rem 0 0.5rem 0;
    font-size: 1rem;
  }
}
