.device-card {
  background-color: $colour-background-mid;
  border: 1px solid $colour-background-light;
  border-radius:4px;
  color:$colour-text;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: start;
  margin:0 20px 2rem 0;
  padding: 1rem;
  opacity: .75;
  width:100%;

  &--current {
    opacity: 1;
  }

  .device-card__left {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
  }

  .device-card__right {
    width: 40%;
  }

  .device-card__item {
    margin-bottom: 2rem;
    padding-right: 2rem;
    width: 100%;

    @media only screen and (min-width: 650px) {
      width: 50%;
    }

    @media only screen and (min-width: 1200px) {
      width: 33%;
    }
  }

  .device-card__item--settings {
    margin-bottom: 1rem;

    p, textarea {
      min-height: 150px;
      outline: none;
      resize: none;
    }
  }

  .device-card__item-cta {
    text-align: right;

    .button {
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  h4 {
    color: $colour-text-heading;
    font-size: 14px;
    margin: 0 0 .25rem 0;
  }

  p {
    min-height: 38px;
    margin: 0;
  }

  textarea {
    background-color: #193c52;
    border: 1px solid #112938;
    padding: 10px;
    width: 100%;
  }
}

.device-card--replacements {
  margin-top: -1.25rem;
  flex-wrap: nowrap;

  .device-card__replacement-sd {
    margin-right: 2rem;

    &:first-child {
      min-width: 130px;
    }
  }

  .device-card__replacement-sd-actions {
    align-self: center;
    margin-left: auto;
    position: relative;

    .icon {
      cursor: pointer;
      height: 30px;
      width: 30px;
    }

    .loader-wrapper {
      margin: 0;
      position: static;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
}
