.container-overlayAnnouncements{
  h3{
    text-transform: uppercase;
    border-bottom: 1px solid #f0b35b;
    font-size: 20px;
    margin: 10px 0;
  }
  h4{
    display: block;
    color: #f4f4f4;
    transition: .3s;
    outline: none;
    pointer-events: none;
    letter-spacing: .5px;
    font-size: 14px;
    border-bottom: 1px solid #f0b35b;
  }
  > div{
    position: relative;
    margin: 12px 0;
  }
  .freq-wrapper{
    > div{
      position: relative;
      margin: 12px 0;
    }
  }
}