.table-cell-img{
  background-color: $colour-background-mid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:6px;
  height: 100%;
  img{
    max-width: 78px;
  }
  .icon{
    width:78px;
    height:78px;
  }
}