.overlay-wrapper{
  //display: none !important;
  position: absolute;
  width: 100%;
  height:100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 998;
  .container-overlay{
    z-index: 999;
    position: absolute !important;
    top: 25%;
    left:25%;
    width:50%;
    height:50%;
    background-color: $colour-background;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.75);
    padding:10px;
    overflow: hidden;
    .container-table{
      .table-controls{
        display: none;
      }
    }
    h2, h3, h4{
      //text-align: center;
      margin: 0 0 .5rem 0;
    }
    .overlay-close{
      position: absolute;
      right:15px;
      top:15px;
      display: flex;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      z-index: 9;
    }
  }
  &.active{
    display:initial;
  }
  &.tall{
    .container-overlay{
      top: 10%;
      left:20%;
      width:60%;
      height:80%;
    }
  }
  &.small{
    .container-overlay{
      height:30%;
    }
  }
  &.large{
    .container-overlay{
      top: 10%;
      left:10%;
      width:80%;
      height:80%;
    }
  }
  &.auto{
    .container-overlay{
      height: auto;
      min-height: 30%;
    }
  }
}
