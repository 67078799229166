.container-auth{
  flex-direction: column;
  width:300px;
  margin:0 auto;
  svg{
    width:100px;
    margin:0 auto;
  }
  .form-auth{
    background-color: transparent;
    .form-submit-auth{
      border-color: $colour-background-mid;
    }
  }
}