.container-businessReports {
  overflow: auto;
  margin-top: 1rem;
  padding-bottom: 100px;
}

.business-reports__pdf-loader-container {
  background: $colour-background;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3000;
}

.business-reports__loader {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.business-reports__header {
  display: flex;

  .form-select-wrapper {
    margin: -5px 0 0 0;
  }
}

#business-reports__report {
  min-width: 1000px;
  margin: 0 0 8rem 0;
  position: relative;

  h2 svg {
    height: 30px;
    padding-right: 32px;
    width: 30px;
  }
}

.business-reports__pdf-download-btn {
  background: #4ba0b3;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 1rem 0 auto;
  padding: .25rem .5rem;
  outline: none;
  z-index: 100;

  &:hover {
    border: 1px solid #fff;
  }
}

