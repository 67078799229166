.container-overlay-playlists-more{
  .copy-form{
    width:100%;
    overflow: scroll;
    button{
      width:100%;
    }
  }
  .merge-form{
    width:100%;
    overflow: scroll;
    .typeahead-wrapper{
      padding:0;
      .icon-ios-search{
        top:7px;
        left:3px;
      }
    }
    .playlist-box{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
      padding:9px 15px;
      background-color: $colour-background-mid;
      border-radius: 9px;
      border:1px solid $colour-text-link;
      margin:6px 0;
      .icon{
        width:33px;
        height:33px;
      }
    }
  }
}