.playlists-importer {
  .button {
    margin: 0 1rem 1rem 0;
  }
}

.container-overlayPlaylistImporter {
  p {
    margin: 0 0 1rem 0;
  }

  .overlay-playlist-importer__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .button {
      min-width: 120px;
    }
  }

  .overlay-playlist-importer__target-track {
    margin: 0 0 1rem 0;
    text-align: center;

    p {
      font-size: .9rem;
      margin: 0 0 .5rem 0;
    }
  }
}
