.table-cell{
  padding:10px;
  font-size: 14px;
  color: $colour-text;
  background-color: $colour-background-mid;
  border-bottom: 1px solid $colour-background-light;
  word-break: break-word;
  &:first-child{
    max-width: 150px;
  }
  &.table-cell-size-small,
  &.table-cell-checkbox {
    text-align: center;
  }
  &.table-cell-size-normal{
    min-width: 100px;
  }
  &.table-cell-play{
    .icon{
      cursor: pointer;
      width:20px;
      height:20px;
      vertical-align: inherit;
    }
  }
  input[type='checkbox'] {
    cursor: pointer;
  }
  &.table-cell-energy{
    .select{
      display: inline;
    }
    .icon-wrapper{
      display: inline;
    }
  }
  &.table-cell-genres-edit{
    .select-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .genre-wrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin:2px;
      padding:1px 3px;
      border: 1px solid $colour-background;
    }
  }
}
