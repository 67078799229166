#root{
  position: relative;
  height: 100%;
  background-color: $colour-primary;
  color:$colour-text;
  .container-app{
    width:100%;
    flex-direction: column;
    overflow: hidden;
  }
}