.table-cell-playlistTracksToApprove {
  .badge-add {
    background: $colour-success;
    color: $colour-primary;
  }

  .badge-remove {
    background: $colour-error;
    color: $colour-primary;
  }
}

.table-cell-actions-playlistTracksToApprove {
  .icon-wrapper {
    .icon-close-circle {
      color: $colour-error;
    }
  }
}
